import logo from './logo.png';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './Account/Dashboard.js';
import Login from "./Login";
import useToken from "./useToken.js";
import {Button} from "reactstrap";


function App() {
    const { token, setToken } = useToken();

    //If cookie is setup, tell react to resume that session
    if(!token) {
        return <Login authToken={setToken} />
    }



    return (
        <div className="App">
            <div className="wrapper">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />

                    <div className="Options">
                        <BrowserRouter>
                            <Routes path="/">
                                <Route path="dashboard" element={<Dashboard />} />
                            </Routes>
                        </BrowserRouter>
                    </div>
                    <Button color="info" onClick={Logout}>Logout!</Button>
                </header>
            </div>
        </div>);
}

function Logout(){
    localStorage.clear();
    window.location.href = '/';
}

export default App;
