import React, {useState} from 'react';
import reactstrap, {Button, Col, Container, Form, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import useToken from "../useToken";
import useSWR from 'swr'



export default function Dashboard() {
    const { token } = useToken();
    const fetcher = (url) => fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': {token}
        }
    }).then((res) => res.json());

    const { data, error, isLoading } = useSWR(['https://dbddaaff-6fb9-4206-839c-11241390bf64.mock.pstmn.io/account', token],
        ([url, token]) => fetcher(url, token))

    //Handle data fetch error
    if (error) {
        return (
            <div>
                <p>Code: ${error.status}</p>
                <p>Message: ${error.statusText}</p>
            </div>
        )
    }


    return isLoading ? (
        <div>Loading...</div>
    ) : (
        <div className="Dashboard-Wrapper">
            <Container>
                <Col>
                    <Form>
                        <FormGroup>
                            {isLoading && <p>Loading...</p>}
                            <Row className="row-cols-auto align-items-center">
                                <Col md={6}>
                                <Label for="userEmail">
                                    Email
                                </Label>
                                <Input
                                    id="userEmail"
                                    name="email"
                                    value={data.email}
                                    type="email"
                                    readOnly/>
                                    </Col>
                                <Col md={6}>
                                    <Label for="userAPIID">
                                    API Addressable User ID
                                </Label>
                                    <Input
                                        id="userAPIID"
                                        name="APIID"
                                        value={data.apiuid}
                                        type="text"
                                        readOnly
                                    />
                                </Col>

                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Label for="newPassword">
                                        New Password
                                    </Label>
                                    <Input
                                        id="newPassword"
                                        name="password"
                                        placeholder="Remember to use a secure, long, randomly generated passphrase!"
                                        type="password"
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label for="newPasswordConfirm">
                                        Confirm New Password
                                    </Label>
                                    <Input
                                        id="newPasswordConfirm"
                                        name="passwordConfirm"
                                        placeholder="Store your password somewhere secure, Like a password manager!"
                                        type="password"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend>
                                Radio Buttons
                            </legend>
                            <FormGroup check>
                                <Input
                                    name="radio1"
                                    type="radio"
                                />
                                {' '}
                                <Label check>
                                    Option one is this and that—be sure to include why it‘s great
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    name="radio1"
                                    type="radio"
                                />
                                {' '}
                                <Label check>
                                    Option two can be something else and selecting it will deselect option one
                                </Label>
                            </FormGroup>
                            <FormGroup
                                check
                                disabled
                            >
                                <Input
                                    disabled
                                    name="radio1"
                                    type="radio"
                                />
                                {' '}
                                <Label check>
                                    Option three is disabled
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox"/>
                            {' '}
                            <Label check>
                                Check me out
                            </Label>
                        </FormGroup>
                        <Button>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Container>
        </div>
    );
}