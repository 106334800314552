import logo from './logo.png';
import './App.css';
import { useState } from 'react';
import PropTypes from "prop-types";
import reactstrap, {Button, Col, Container, Form, FormGroup, FormText, Input, Label, Row} from 'reactstrap';

async function loginUser(credentials) {
    return fetch('https://dbddaaff-6fb9-4206-839c-11241390bf64.mock.pstmn.io/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login({authToken}) {
    const [user_Input_Email, setUserEmail] = useState();
    const [user_Input_Pass, setPassword] = useState();

    //When submitButtonPressed is triggered, attempt verification of user credentials.
    const submitButtonPressed = async e => {
        e.preventDefault();
        const token = await loginUser({
            user_Input_Email,
            user_Input_Pass
        });
        authToken(token);
    }


    /*const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }*/

    return (
            <Container >
                <div className="Login-Text-Input">
                    <Form name="LoginForm" onSubmit={submitButtonPressed}>
                        <div>
                            <FormGroup row>
                                <Col>
                                    <Label className="login-label">Email:
                                        <Input
                                            type="text"
                                            name="email"
                                            value={user_Input_Email || ""}
                                            onChange={e => setUserEmail(e.target.value)}
                                            className="Login-Text-Input" id="username"></Input>
                                    </Label>
                                </Col>
                            </FormGroup>
                            <FormGroup Row>
                                <Col>
                                    <div>
                                        <Label className="login-label">Password:
                                            <Input
                                                className="Login-Text-Input"
                                                type='password'
                                                name="password"
                                                value={user_Input_Pass || ""}
                                                onChange={e => setPassword(e.target.value)}></Input>
                                        </Label>
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <div className="Login-Submit">
                                    <Input type="submit"/>
                                </div>
                            </FormGroup>
                        </div>
                    </Form>
                </div>
            </Container>);
}
Login.propTypes = {
    authToken: PropTypes.func.isRequired
}